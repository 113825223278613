import { default as _91slug_93mLNDJar9djMeta } from "/opt/build/repo/nuxt/pages/[slug].vue?macro=true";
import { default as _4049qdkGWw3GnMeta } from "/opt/build/repo/nuxt/pages/404.vue?macro=true";
import { default as _91slug_9372bN2sFmuKMeta } from "/opt/build/repo/nuxt/pages/about/[slug].vue?macro=true";
import { default as stockists1CzWcZvXtVMeta } from "/opt/build/repo/nuxt/pages/about/stockists.vue?macro=true";
import { default as indext1gLk5rFm2Meta } from "/opt/build/repo/nuxt/pages/index.vue?macro=true";
import { default as _91slug_93XvbPTekhrcMeta } from "/opt/build/repo/nuxt/pages/notes/[slug].vue?macro=true";
import { default as indexGBdk6HGEfWMeta } from "/opt/build/repo/nuxt/pages/notes/index.vue?macro=true";
import { default as _91slug_93PNfsMWjs6iMeta } from "/opt/build/repo/nuxt/pages/shop/[slug].vue?macro=true";
import { default as indexOMZXxZIuJxMeta } from "/opt/build/repo/nuxt/pages/shop/index.vue?macro=true";
import { default as component_45stubILoTYOQseEMeta } from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_rollup@4.22.4_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubILoTYOQseE } from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_rollup@4.22.4_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/opt/build/repo/nuxt/pages/[slug].vue")
  },
  {
    name: "404",
    path: "/404",
    component: () => import("/opt/build/repo/nuxt/pages/404.vue")
  },
  {
    name: "about-slug",
    path: "/about/:slug()",
    component: () => import("/opt/build/repo/nuxt/pages/about/[slug].vue")
  },
  {
    name: "about-stockists",
    path: "/about/stockists",
    component: () => import("/opt/build/repo/nuxt/pages/about/stockists.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/nuxt/pages/index.vue")
  },
  {
    name: "notes-slug",
    path: "/notes/:slug()",
    meta: _91slug_93XvbPTekhrcMeta || {},
    component: () => import("/opt/build/repo/nuxt/pages/notes/[slug].vue")
  },
  {
    name: "notes",
    path: "/notes",
    meta: indexGBdk6HGEfWMeta || {},
    component: () => import("/opt/build/repo/nuxt/pages/notes/index.vue")
  },
  {
    name: "shop-slug",
    path: "/shop/:slug()",
    component: () => import("/opt/build/repo/nuxt/pages/shop/[slug].vue")
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/opt/build/repo/nuxt/pages/shop/index.vue")
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/tin-guide",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/contact-us",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/terms-of-service",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/privacy-policy",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/refund-policy",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/faqs",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/shipping",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/shipping-information",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/shop/3-litre-tin-punchy",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/shop/3-litre-tin-smooth",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/shop/tin-subscription-repeats",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/shop/copy-of-tin-subscription",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/shop/tin-subscription-ongoing",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/QR1",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/QR2",
    component: component_45stubILoTYOQseE
  },
  {
    name: component_45stubILoTYOQseEMeta?.name,
    path: "/QR3",
    component: component_45stubILoTYOQseE
  }
]