export const useCartStore = defineStore("cart", () => {
  const active = ref(false);
  const cart = ref({
    id: "",
    lines: { edges: [] },
    cost: {
      subtotalAmount: {},
      totalAmount: {},
    },
    checkoutUrl: "",
  });

  // Getters
  const id = computed(() => (cart.value?.id ? cart.value.id : ""));
  const items = computed(() =>
    cart.value && cart.value.lines ? cart.value.lines.edges : []
  );
  const size = computed(() =>
    items.value.length > 0
      ? items.value.reduce((acc, cv) => acc + cv.node.quantity, 0)
      : 0
  );
  const subtotal = computed(() =>
    cart.value && cart.value?.cost
      ? formatPrice(cart.value.cost.subtotalAmount?.amount)
      : null
  );

  const tax = computed(() => {
    if (cart.value && cart.value.cost) {
      const tax = cart.value.cost?.totalTaxAmount
        ? cart.value.cost?.totalTaxAmount
        : {
            amount: 0,
            currencyCode: "AUD",
          };

      return formatCurrency(tax.amount, tax.currencyCode);
    }
  });

  const total = computed(() => {
    if (cart.value && cart.value.cost) {
      const total = cart.value.cost.totalAmount;
      return formatCurrency(total.amount, total.currencyCode);
    }
  });

  // Savings
  const savingsUpsell = ref(null);

  const savingsSubscriptions = computed(() => {
    return items.value.reduce((acc, cv) => {
      const compareAtPrice =
        cv.node?.sellingPlanAllocation?.priceAdjustments[0].compareAtPrice
          .amount;
      const price =
        cv.node?.sellingPlanAllocation?.priceAdjustments[0].price.amount;

      if (compareAtPrice && price) {
        const discount = parseFloat(compareAtPrice) - parseFloat(price);
        acc += discount * cv.node.quantity;
      }

      return acc;
    }, 0);
  });

  // Actions
  function open() {
    active.value = true;
  }

  function close() {
    active.value = false;
  }

  function toggle() {
    active.value = !active.value;
  }

  function updateBase(response) {
    window.localStorage.removeItem("goldiCart");
    window.localStorage.setItem("goldiCart", JSON.stringify(response));
    cart.value = response;
  }

  function updateId(id) {
    window.localStorage.removeItem("goldiCartId");
    window.localStorage.setItem("goldiCartId", id);
  }

  return {
    active,
    cart,
    id,
    items,
    size,
    subtotal,
    tax,
    total,
    open,
    close,
    toggle,
    updateBase,
    updateId,
    savingsUpsell,
    savingsSubscriptions,
  };
});
