
import * as sanityRuntime$gjGHbAWaZO from '/opt/build/repo/nuxt/node_modules/.pnpm/@nuxt+image@1.8.0_rollup@4.22.4/node_modules/@nuxt/image/dist/runtime/providers/sanity'
import * as netlifyRuntime$91HiDxxHUL from '/opt/build/repo/nuxt/node_modules/.pnpm/@nuxt+image@1.8.0_rollup@4.22.4/node_modules/@nuxt/image/dist/runtime/providers/netlifyImageCdn'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "netlify",
  "domains": [
    "cdn.sanity.io"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['sanity']: { provider: sanityRuntime$gjGHbAWaZO, defaults: {"projectId":"l9lidwjv"} },
  ['netlify']: { provider: netlifyRuntime$91HiDxxHUL, defaults: {} }
}
        