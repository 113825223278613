import revive_payload_client_fSmrVYvqei from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_rollup@4.22.4_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_5JOpLAcCek from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_rollup@4.22.4_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KWttXV48yy from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_rollup@4.22.4_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_NtBknU9ayV from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_rollup@4.22.4_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_W49ETs7mPu from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_rollup@4.22.4_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Wsz7vA5qXC from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_rollup@4.22.4_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_HdnLA8HSmg from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_rollup@4.22.4_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_db3nHR1ehK from "/opt/build/repo/nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.22.4_typescript@5.6.2_vue@3.5.8/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_X0qj2YRMJE from "/opt/build/repo/nuxt/node_modules/.pnpm/nuxt@3.13.2_eslint@9.11.1_rollup@4.22.4_sass@1.79.3_typescript@5.6.2_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_Fic6cwt6p9 from "/opt/build/repo/nuxt/node_modules/.pnpm/@vueuse+motion@2.2.5_rollup@4.22.4_vue@3.5.8/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import sentry_client_NU6PxeqCpu from "/opt/build/repo/nuxt/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_ecuhk3tlzmye4imm63jxd3mypq/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/build/repo/nuxt/.nuxt/sentry-client-config.mjs";
import Vue3Marquee_client_vQvEBM1GfF from "/opt/build/repo/nuxt/plugins/Vue3Marquee.client.js";
import preview_2IjZF69ftJ from "/opt/build/repo/nuxt/plugins/preview.js";
import sanity_image_builder_bQsAocKPDm from "/opt/build/repo/nuxt/plugins/sanity-image-builder.js";
import sanity_start_szherZGTC7 from "/opt/build/repo/nuxt/plugins/sanity-start.js";
import vue_gtm_client_Cm24Dsc5BN from "/opt/build/repo/nuxt/plugins/vue-gtm.client.js";
export default [
  revive_payload_client_fSmrVYvqei,
  unhead_5JOpLAcCek,
  router_KWttXV48yy,
  payload_client_NtBknU9ayV,
  navigation_repaint_client_W49ETs7mPu,
  check_outdated_build_client_Wsz7vA5qXC,
  chunk_reload_client_HdnLA8HSmg,
  plugin_vue3_db3nHR1ehK,
  components_plugin_KR1HBZs4kY,
  prefetch_client_X0qj2YRMJE,
  motion_Fic6cwt6p9,
  sentry_client_NU6PxeqCpu,
  sentry_client_config_o34nk2sJbg,
  Vue3Marquee_client_vQvEBM1GfF,
  preview_2IjZF69ftJ,
  sanity_image_builder_bQsAocKPDm,
  sanity_start_szherZGTC7,
  vue_gtm_client_Cm24Dsc5BN
]