<script setup>
import { useCartStore } from "~/stores/cart";
import { useSiteStore } from "~/stores/site";

const cartStore = useCartStore();
const site = useSiteStore();

const threshold = computed(() => {
  return site.settings?.freeShippingThreshold || 50;
});
const remaining = computed(() => {
  if (!cartStore?.cart?.id || !cartStore.subtotal) return;
  const val = threshold.value - cartStore.subtotal;
  return (Math.round(val * 100) / 100).toFixed(2);
});
const progress = computed(() => {
  if (!cartStore?.cart?.id || !cartStore.subtotal) return;
  const rawvalue =
    remaining.value < 0 ? 100 : (cartStore.subtotal / threshold.value) * 100;
  return Math.round(rawvalue * 100) / 100;
});
</script>

<template>
  <div v-if="progress" class="shipping-calculator center">
    <div class="inner cucina-s" :style="`--progress:${progress}%`">
      <p v-if="remaining > 0">
        Spend
        <span class="social-s">{{ "$" + remaining }}</span>
        more for free shipping
      </p>
      <p v-else class="celebrate">You've reached free shipping!</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.shipping-calculator {
  padding: 0 var(--s);
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.inner {
  padding: var(--xs) 0;
  position: relative;

  &::before,
  &::after {
    content: "";
    height: 5px;
    background: white;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 2px;
    border: 1px solid var(--black);
  }

  &::after {
    width: var(--progress);
    background: var(--lt-yellow);
    transition: width 0.25s ease;
    border-right: 1px solid var(--lt-yellow);
  }
}

.celebrate {
  animation: celebration 1s ease-in-out;
}

@keyframes celebration {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
