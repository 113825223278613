<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27 49.8421L13.5 35.5L0 49.8421V56.509L10.5 44.5V51H10.5C10.5 72.5391 27.9609 90 49.5 90C60.2931 90 70.0669 85.6117 77.125 78.5293L72.875 74.294C66.8967 80.2929 58.6341 84 49.5 84C31.2746 84 16.5 69.2254 16.5 51V44.5L27 56.509V49.8421Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.7492 28.1728C30.8004 21.3168 39.6442 17 49.5 17C67.7254 17 82.5 31.7746 82.5 50H88.5C88.5 28.4609 71.0391 11 49.5 11C37.8513 11 27.3926 16.1107 20.2508 24.2024L24.7492 28.1728ZM72 51.167L85.5 65.509L99 51.167V44.5001L88.5 56.5091V50.009L82.5 50.0091V56.5091L72 44.5001V51.167Z"
      fill="black"
    />
  </svg>
</template>
