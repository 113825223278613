import { createGtm } from "@gtm-support/vue-gtm";

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { mode },
  } = useRuntimeConfig();

  nuxtApp.vueApp.use(
    createGtm({
      id: "GTM-NQV8L7X",
      enabled: mode === "production",
      vueRouter: useRouter(),
    })
  );
});
