export default async function (query: string, variables: object) {
  const runtimeConfig = useRuntimeConfig();

  const result = await $fetch(runtimeConfig.public.shopifyApiEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Storefront-Access-Token":
        runtimeConfig.public.shopifyStorefrontAccess,
    },
    body: {
      query,
      variables: variables || null,
    },
  });

  // guard against errors
  if (result.errors) {
    console.log(result);
  }

  return result;
}
