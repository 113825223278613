export default async function (id: string) {
  const cartStore = useCartStore();

  const result = await shopifyPost(queryShopifyCart, { id });

  // Save cart to Store
  cartStore.cart = result?.data?.cartCreate?.cart;

  // If errors, remove the CARTID from localStorage
  if (result.errors) {
    // console.log("clearing cart from localStorage");
    window.localStorage.removeItem("goldiCartId");
    return;
  }

  // Save cart to Store
  cartStore.cart = result?.data?.cart;

  // Set localStorage CARTID
  window.localStorage.setItem("goldiCartId", result?.data?.cart?.id);

  // Return query result
  return result;
}
