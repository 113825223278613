export default async function () {
  const loading = ref(false);
  const success = ref(null);
  const valid = ref(false);
  const response = ref(null);

  const message = computed(() =>
    success.value
      ? "Thanks for signing up"
      : success.value === false
      ? "Already signed up"
      : loading.value
      ? "Loading"
      : "Submit"
  );

  async function submit(e) {
    e.preventDefault();
    const form = e.target;
    valid.value = form.checkValidity(); // Make sure email form is required and valid
    if (!valid.value) return;

    loading.value = true;
    const formData = new FormData(form);

    // Send to our API route
    response.value = await $fetch("/api/subscribe", {
      method: "POST",
      body: { email: formData.get("email") },
    });

    // If response is empty, it means the email was already subscribed
    if (response.value?.statusCode === 200) {
      success.value = true;

      // Send to datalayer
      sendToDataLayer({
        event: "newsletter_subscribe",
        form_location: form.dataset.location || null,
        form_data: Object.fromEntries(formData.entries()),
      });
    } else {
      success.value = false;
    }

    // Reset
    setTimeout(() => {
      loading.value = false;
      success.value = null;
      response.value = null;
      form.reset();
    }, 8000);
  }

  return {
    loading,
    success,
    response,
    message,
    submit,
  };
}
