export default function (header: Ref<HTMLElement | null>) {
  const uiStore = useUiStore();

  const setCssVar = () => {
    const headerHeight = header.value?.offsetHeight!;
    document.documentElement.style.setProperty("--header", `${headerHeight}px`);
    uiStore.headerHeight = headerHeight;
  };

  // Set up window interactions
  onMounted(() => {
    // Base state
    setCssVar();
    // Update on resize
    window.addEventListener("resize", setCssVar);
  });

  // Remove on dismount
  onUnmounted(() => window.removeEventListener("resize", setCssVar));
}
