import { imageVideo } from "./media";

export const productInner = `
  thumbnail,
  shortTitle,
  reviewsCount,
  reviewsAverage,
  priceRangeOverride,
  hoverVideo{
    ${imageVideo}
  },
  store{
    previewImageUrl,
    title,
    priceRange,
    id,
    slug,
    productType,
    descriptionHtml
  },
  "tin": *[_type == "productVariant" && store.productGid == ^.store.gid && store.title match "Tin"][0].store.id
`;

export const product = `product->{
  ${productInner}
}`;

export const variant = `variant->{
  store{
    previewImageUrl,
    title,
    id,
    price,
  }
}`;

export const productWithVariant = `productWithVariant{
  ${product},
  ${variant}
}`;
