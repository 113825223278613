export default async function (
  merchandiseId = "",
  quantity = 0,
  attributes = []
) {
  // Get Cart Store
  const cartStore = useCartStore();

  if (!cartStore.id) {
    // console.log("No valid cart");
    return;
  }

  const variables = {
    cartId: cartStore.id,
    lines: {
      id: merchandiseId,
      quantity,
      attributes,
    },
  };

  const result = await shopifyPost(mutationShopifyUpdateLineItems, variables);

  // Guard if errors
  if (result.errors) return;

  // Save cart to store
  cartStore.cart = result?.data?.cartLinesUpdate?.cart;
  // console.log("cart update:", result);

  // Return query results
  return result;
}
