interface Slug {
  current: string;
}

interface LinkObject {
  _type: string;
  slug: Slug;
}

export default function resolveInternalLink(
  type: string | LinkObject,
  slug: Slug
): string | undefined {
  if (!type) return;

  // If the type is entered as a string, resolve traditionally
  if (typeof type === "string") {
    switch (type) {
      // Singletons
      case "shop":
        return `/shop`;
      case "blog":
        return `/read`;
      // Documents
      case "page":
        return `/${slug.current}`;
      case "product":
        return `/shop/${slug.current}`;
      case "pageInfo":
        return `/about/${slug.current}`;
      case "blogArticle":
        return `/read/${slug.current}`;
      case "stockistsPage":
        return `/about/stockists`;
      default:
        return `/`;
    }
  }

  // else if link is entered as an object
  switch (type._type) {
    // Singletons
    case "shop":
      return `/shop`;
    case "blog":
      return `/read`;
    // Documents
    case "page":
      return `/${type.slug.current}`;
    case "product":
      return `/shop/${type.slug.current}`;
    case "pageInfo":
      return `/about/${type.slug.current}`;
    case "blogArticle":
      return `/read/${type.slug.current}`;
    case "stockistsPage":
      return `/about/stockists`;
    default:
      return `/`;
  }
}
