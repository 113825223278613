<script setup>
defineProps({
  data: { type: Object, default: null },
});

const isPlaying = ref(false);
const isMuted = ref(false);
const isStalled = ref(false);
const videoRef = ref(null);

// Methods to handle play/pause and mute/unmute
const togglePlayPause = () => {
  if (videoRef.value) {
    if (isPlaying.value) {
      videoRef.value.pause();
    } else {
      videoRef.value.play();
    }
    isPlaying.value = !isPlaying.value;
  }
};
const toggleMuteUnmute = () => {
  if (videoRef.value) {
    videoRef.value.muted = !isMuted.value;
    isMuted.value = !isMuted.value;
  }
};

onMounted(() => {
  if (videoRef.value) {
    videoRef.value.addEventListener("play", () => {
      isPlaying.value = true;
      isStalled.value = false;
    });
    videoRef.value.addEventListener("pause", () => {
      isPlaying.value = false;
    });
    videoRef.value.addEventListener("ended", () => {
      isPlaying.value = false;
    });
  }

  // stalled
  videoRef.value.addEventListener(
    "stalled",
    () => {
      isStalled.value = true;
    },
    { once: true }
  );

  // videoRef.value.addEventListener("canplay", () => {
  //   videoRef.value.play();
  //   isPlaying.value = true;
  // });
});
</script>

<template>
  <div v-if="data?.mp4 || data?.webm" class="flex-col gap-xs">
    <button
      class="vid-btn"
      :class="{ isStalled, isPlaying }"
      @click="togglePlayPause"
    >
      <video ref="videoRef" playsinline loop preload="auto">
        <source
          v-if="data?.mp4"
          type="video/mp4"
          :src="`${data.mp4}#t=0.001`"
        />
        <source v-if="data?.webm" type="video/webm" :src="data.webm" />
      </video>

      <span class="inner-btn btn">Play</span>
    </button>

    <div class="flex flex-justify text-mono dk-grey">
      <button @click="togglePlayPause">
        {{ isPlaying ? "Pause" : "Play" }}
      </button>
      <button @click="toggleMuteUnmute">
        {{ isMuted ? "Sound on" : "Sound off" }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
video {
  width: 100%;
  display: block;
  height: auto;
}

.isStalled {
  animation: pulse 0.5s infinite;
}

.vid-btn {
  position: relative;
  width: 100%;
  height: 100%;

  .inner-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.isPlaying {
    .inner-btn {
      display: none;
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>
